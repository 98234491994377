import _ from 'lodash'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { InputText } from '../components/form/InputText'
import { ApiPost } from '../lib/AppHelper'
import { AppStore } from '../lib/AppStore'
import Main from './Main'

export default class ForgotPassword extends Component {
  state = {
    params: {
      email: "",
      otp_sent: false,
      otp: "",
      password: "",
      rpassword: "",
    },
    errors: {}
  }

  MessageRef = React.createRef();

  render() {
    // const { loading } = this.props
    const { params, errors, otp_sent } = this.state

    return (
      <Main MessageRef={this.MessageRef} header={false}>
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <h2 className="mt-3 text-3xl font-extrabold text-gray-900">{!otp_sent ? 'Forgot' : 'Reset'} Password</h2>
              </div>

              <div className="mt-3">
                <div className="mb-4">
                  <form action="#" method="POST" className="space-y-4">
                    {!otp_sent ? <>
                      <div>
                        <InputText label="Enter your email" onEnter={this.resetPassword} error={errors.email} attrs={{ type: 'email', value: params.email, onChange: (e) => this.onChangeHandler(e, 'email') }} />
                      </div>
                    </> : <>
                      <div>
                        <InputText label="Enter 4 Digit OTP" onEnter={this.resetPassword} error={errors.otp} attrs={{ value: params.otp, maxLength: 4, onChange: (e) => this.onChangeHandler({ target: { value: e.target.value.replace(/\D/, '') } }, 'otp') }} />
                      </div>

                      <div className="flex space-x-4">
                        <div className="flex-1">
                          <InputText label="Password" onEnter={this.resetPassword} error={errors.password} attrs={{ value: params.password, type: 'password', onChange: (e) => this.onChangeHandler(e, 'password') }} />
                        </div>
                        <div className="flex-1">
                          <InputText label="Confirm Password" onEnter={this.resetPassword} error={errors.rpassword} attrs={{ value: params.rpassword, type: 'password', onChange: (e) => this.onChangeHandler(e, 'rpassword') }} />
                        </div>
                      </div>
                    </>}
                    <div>
                      <button
                        onClick={this.resetPassword}
                        type="button"
                        className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div>

                  <div>
                    <div className="mt-8 relative">
                      <div className="relative flex justify-center mt-4">
                        <Link className="font-medium text-black underline hover:text-primary-600" to="/">Go Back</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1 text-center">
            <h4 className="absolute mt-2 font-normal text-white z-10 right-5 text-6xl w-full">For Food Lovers</h4>
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={`${process.env.PUBLIC_URL}/bg.jpeg`}
              alt=""
            />
          </div>
        </div>
      </Main>
    )
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params })
  }

  resetPassword = async () => {
    if (this.state.otp_sent) {
      AppStore.dispatch({ type: 'LOADING', loading: true })
    } else {
      AppStore.dispatch({ type: 'LOADING', loading: true })

      const response = await ApiPost('customer/login/forgot', this.state.params);

      if (response.status === 'error') {
        if (_.isEmpty(response.errors)) {
          this.MessageRef.current.show(response.message, 'error');
        } else {
          this.setState({ errors: response.errors })
        }
      } else {
        this.MessageRef.current.show(response.data.message, 'success');
        this.setState({ otp_sent: true })
      }
      AppStore.dispatch({ type: 'LOADING', loading: false })
    }
  }
}