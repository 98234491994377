import React from 'react'

export default function InputLabel({ label = "", labelLink = null }) {

  if (!label) return false;

  return (
    <label htmlFor="password" className="block font-medium text-gray-700">
      {label}
    </label>
  )
}
