import React from 'react'
import { connect } from 'react-redux'
import InputLabel from './InputLabel';

export function InputText({ label = "", attrs = {}, error = '', onEnter = () => { }, prefix = "" }) {

   return (
      <div className="mb-5">
         <InputLabel label={label} />
         <div className="mt-2 relative flex">
            {prefix ? <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">{prefix}</span> : false}
            <input
               type="text"
               className={[`appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500`, prefix ? 'rounded-none rounded-r-md' : 'rounded-md'].join(' ')}
               onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                     onEnter()
                  }
               }}
               {...attrs}
            />
         </div>
         {error ? <p className="mt-2 text-sm text-red-500">{error.join(' ')}</p> : false}
      </div>
   )
}

const mapStateToProps = (state) => {
   const { loading } = state
   return { loading: loading }
};

export default connect(mapStateToProps)(props => {
   return <InputText {...props} />
})