import _ from 'lodash'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { InputText } from '../components/form/InputText'
import { ApiPost, ApiURL, removeData, setData, setError } from '../lib/AppHelper'
import { AppStore } from '../lib/AppStore'
import Main from './Main'

export default class Login extends Component {
  state = {
    params: {
      email: '',
      password: ''
    },
    errors: {}
  }

  MessageRef = React.createRef();

  render() {
    // const { loading } = this.props
    const { params, errors } = this.state

    return (
      <Main MessageRef={this.MessageRef} header={false}>
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div>
                  <img
                    className="h-28 w-auto -ml-4 -mb-6"
                    src={`${process.env.PUBLIC_URL}/icon.png`}
                    alt="Workflow"
                  />
                </div>
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Welcome to<br />Food Lovers Voucher</h2>
                <h4 className="mt-8 text-xl font-normal text-gray-900">Log in to your account</h4>
              </div>

              <div className="mt-3">
                <div className="mb-4">
                  <form action="#" method="POST" className="space-y-4">
                    <div>
                      <InputText onEnter={this.doLogin} label="Email address" attrs={{ type: 'email', value: params.email, onChange: (e) => this.onChangeHandler(e, 'email') }} error={errors.email} />
                    </div>

                    <div className="space-y-1">
                      <InputText onEnter={this.doLogin} label="Password" attrs={{ type: 'password', value: params.password, onChange: (e) => this.onChangeHandler(e, 'password') }} error={errors.password} />
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                      </div>

                      <div className="">
                        <Link to="/forgot-password" className="font-medium text-primary-500 hover:text-primary-600">
                          Forgot your password?
                        </Link>
                      </div>
                    </div>

                    <div>
                      <button
                        onClick={this.doLogin}
                        type="button"
                        className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="mt-4 relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-gray-500">Or continue with</span>
                    </div>
                  </div>
                  <div>
                    <div className="mt-3 grid grid-cols-2 gap-3">
                      <div>
                        <a
                          href={ApiURL(`customer/login/facebook?linkingUrl=${window.location}?`)}
                          className="w-full inline-flex justify-center py-2 px-4 border bg-facebook border-gray-300 rounded-md shadow-sm bg-white font-medium text-gray-500"
                        >
                          <span className="sr-only">Sign in with Facebook</span>
                          <img alt="facebook" className="w-6" src={`${process.env.PUBLIC_URL}/facebook.png`} />
                        </a>
                      </div>

                      <div>
                        <a
                          href={ApiURL(`customer/login/google?linkingUrl=${window.location}?`)}
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white font-medium text-gray-500 bg-gray-100"
                        >
                          <span className="sr-only">Sign in with Google</span>
                          <img alt="google" className="w-6" src={`${process.env.PUBLIC_URL}/google.png`} />
                        </a>
                      </div>
                    </div>
                    <div className="mt-8 relative">
                      <div className="relative flex justify-center">
                        <span className="px-2 bg-white text-gray-500 text-sm">Don't have an account?</span>
                      </div>
                      <div className="relative flex justify-center">
                        <Link className="font-medium text-primary-500 hover:text-primary-600" to="/register">Sign Up</Link>
                      </div>
                      <div className="relative flex justify-center mt-4">
                        <Link className="font-medium text-black underline hover:text-primary-600" to="/home">Continue as a Guest</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1 text-center">
            <h4 className="absolute mt-2 font-normal text-white z-10 right-5 text-6xl w-full">For Food Lovers</h4>
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={`${process.env.PUBLIC_URL}/bg.jpeg`}
              alt=""
            />
          </div>
        </div>
      </Main>
    )
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params })
  }

  doLogin = async () => {
    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost('customer/login/email', this.state.params);

    if (response.status === 'error') {
      if (_.isEmpty(response.errors)) {
        this.MessageRef.current.show(response.message, 'error');
      } else {
        this.setState({ errors: response.errors })
      }
    } else {
      await setData('access_token', response.data.access_token, () => {
        this.LetsLogin(response.data.access_token);
      });
    }
    AppStore.dispatch({ type: 'LOADING', loading: false })
  }

  LetsLogin = async (token) => {
    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost('customer/me');

    if (response.status === 'success') {
      await setData('@email', response.data.email);
      if (!response.data.country) {
        await setError('Please complete your profile.')
      }
      AppStore.dispatch({ type: 'SIGN_IN', token: token, user: response.data });
    } else {
      await removeData('access_token');
      AppStore.dispatch({ type: 'SIGN_OUT' });
    }
    AppStore.dispatch({ type: 'LOADING', loading: false })
  }
}