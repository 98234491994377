import { AppStore } from "./AppStore";

const ENDPOINT = 'https://foodlovers.tmpsite.co';
// const ENDPOINT = 'http://192.168.0.103:8000';

const API_ENDPOINT = `${ENDPOINT}/api`;

export const ApiURL = (endpoint) => {
   return `${API_ENDPOINT}/${endpoint}`
}

export const ImageURL = (image) => {
   return `${ENDPOINT}/${image}`
}

export const CONFIG = { "food_styles": { "1": "American", "2": "Italian", "3": "Mexican", "4": "Chinese", "5": "Thai", "6": "Indian", "7": "Japanese", "8": "Greek", "9": "French", "10": "Jamaican", "11": "Lebanese", "12": "Moroccan", "13": "Mediterranean", "14": "Spanish", "15": "Vietnamese", "16": "Korean", "17": "Turkish", "18": "Caribbean", "19": "Russian", "20": "Austrian", "21": "Hungarian", "22": "Armenian", "23": "Isreali", "24": "Polish", "25": "Czech Republic", "26": "Irish", "27": "English" }, "food_prices": { "1": "$", "2": "$$", "3": "$$$", "4": "$$$$" }, "food_venues": { "1": "Truck", "2": "Small Eatery", "3": "Restaurant", "4": "Fine Dining" }, "food_conveniences": { "1": "Open 24x7", "2": "Take Out", "3": "Eat In", "4": "Curbside Pickup", "5": "Delivery", "6": "Live Music", "7": "Free Parking", "8": "Outdoor Seating" } };

const AsyncStorage = {
   setItem: async function (key, value) {
      await null;
      return localStorage.setItem(key, value);
   },
   getItem: async function (key) {
      await null;
      return localStorage.getItem(key);
   },
   removeItem: async function (key) {
      await null;
      return localStorage.removeItem(key);
   }
};

export async function ApiPost(endpoint, Params = {}, AbortController = null) {
   try {
      let MyHeaders = new Headers();
      MyHeaders.append("Accept", `application/json`);
      if (Params instanceof FormData) {
         // MyHeaders.append("Content-Type", `multipart/form-data`);
      } else {
         MyHeaders.append("Content-Type", `application/json`);
         MyHeaders.append("cache-control", `no-cache`);
      }

      const token = await getData('access_token');
      if (token !== null && token !== '') {
         MyHeaders.append("Authorization", `Bearer ${token}`);
      }

      let RequestOptions = {
         method: 'POST',
         headers: MyHeaders,
         body: (Params instanceof FormData) ? Params : JSON.stringify(Params)
      };

      let response;

      if (AbortController !== null) {
         RequestOptions.signal = AbortController.signal;
      }

      response = await fetch(ApiURL(endpoint), RequestOptions);

      let json = await response.json();


      if (json.status === 'error' && json.code === 401) {
         await AppLogout();
      }
      return json;
   } catch (e) {
      if (e.name !== 'AbortError') {
         return { status: 'error', code: 0, message: e }
      } else {
         return { status: 'aborted' }
      }
   }
}

export async function ApiGet(endpoint, Params = {}, AbortController = null) {
   try {
      let MyHeaders = new Headers();
      MyHeaders.append("Accept", `application/json`);
      MyHeaders.append("Content-Type", `application/json`);
      MyHeaders.append("cache-control", `no-cache`);

      const token = await getData('access_token');
      if (token !== null && token !== '') {
         MyHeaders.append("Authorization", `Bearer ${token}`);
      }

      let RequestOptions = {
         method: 'GET',
         headers: MyHeaders
      };

      let response;

      if (AbortController !== null) {
         RequestOptions.signal = AbortController.signal;
      }

      var url = new URL(ApiURL(endpoint));
      Object.keys(Params).forEach(key => url.searchParams.append(key, Params[key]))

      response = await fetch(url, RequestOptions);
      let json = await response.json();
      if (json.status === 'error' && json.code === 401) {
         await AppLogout();
      }
      return json;
   } catch (e) {
      if (e.name !== 'AbortError') {
         return { status: 'error', code: 0, message: e }
      } else {
         return { status: 'aborted' }
      }
   }
}

export const setData = async (key, value, callback = () => { }) => {
   try {
      await AsyncStorage.setItem(key, JSON.stringify({ value: value }))
   } catch (e) { }
   callback();
   return true;
}

export const removeData = async (key, callback = () => { }) => {
   try {
      await AsyncStorage.removeItem(key)
   } catch (e) { }
   callback();
}

export const getData = async (key) => {
   try {
      const value = await AsyncStorage.getItem(key)
      if (value === null) return null;
      const json = JSON.parse(value)
      return json.value;
   } catch (e) {
      // saving error
   }
}

export const pullData = async (key) => {
   try {
      const value = await AsyncStorage.getItem(key)
      if (value === null) return null;
      const json = JSON.parse(value)
      await removeData(key);
      return json.value;
   } catch (e) {
      // saving error
   }
}

export const setError = async (message, callback = () => { }) => {
   await setData('@error', message, callback);
}

export const getError = async () => {
   const error = await pullData('@error');
   return error;
}

export const setMessage = async (message, callback = () => { }) => {
   await setData('@message', message, callback);
}

export const getMessage = async () => {
   const message = await pullData('@message');
   return message;
}

export const AppLogout = async () => {
   AppStore.dispatch({ type: 'LOADING', loading: true })
   await removeData('access_token', () => {
      AppStore.dispatch({ type: 'SIGN_OUT' })
      AppStore.dispatch({ type: 'LOADING', loading: false })
   })
   return true;
}