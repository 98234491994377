import { LocationMarkerIcon, SearchIcon, XIcon } from '@heroicons/react/outline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AppRow, RestaurantItem } from '../components/AppComponents'
import SearchFilters from '../components/SearchFilters'
import { ApiPost } from '../lib/AppHelper'
import { AppStore } from '../lib/AppStore'
// import { AppStore } from '../lib/AppStore'
import Main from './Main'

export class Search extends Component {

  MessageRef = React.createRef();
  searchInput = React.createRef();

  state = {
    term: "",
    current_location: {
      latitude: null,
      longitude: null
    },
    useNearBy: false,
    restaurants: [],
    searching: false,
    filters: {
      food_styles: [],
      food_price: null,
      food_venues: [],
      food_conveniences: [],
    }
  }

  render() {
    const { user } = this.props;
    const { term, restaurants, useNearBy } = this.state;

    return (
      <Main MessageRef={this.MessageRef}>
        <AppRow>
          <div className="flex-1 rounded-lg bg-primary">
            <div className="px-6 py-6 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
              <div className="xl:w-0 xl:flex-1">
                <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
                  Explore Local Food Vendors
                </h2>
                <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-200">
                  Search near by food vendors.
                </p>
              </div>
              <div className="mt-8 sm:w-full sm:max-w-sm xl:mt-0 xl:ml-8">
                <div className="sm:flex">
                  <div className="flex-1 relative">
                    <label className="sr-only">
                      Search
                    </label>
                    <input
                      ref={this.searchInput}
                      type="text"
                      value={term}
                      onChange={(e) => this.setState({ term: e.target.value })}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.onSearch()
                        }
                      }}
                      className="w-full border-white px-5 pr-14 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-400 rounded-md"
                      placeholder="Search"
                    />
                    <button
                      type="button"
                      onClick={this.onSearch}
                      className="absolute top-1.5 rounded-md right-2 w-full self-start text-gray-500 flex items-center justify-center px-4 bg-transparent py-2 border border-transparent text-base font-medium focus:bg-primary focus:text-white focus:outline-none focus:ring-0 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                    >
                      <SearchIcon className="w-5" />
                    </button>
                    <div className="d-flex" style={{display: "flex"}}>
                        <div className="mt-3 text-white underline font-medium space-x-1 text-sm" >
                        <SearchFilters onApply={(filters) => { this.setState({ filters: filters }, () => this.doSearch()) }} filters={this.state.filters} currency={user && user.country ? user.country.currency_symbol : '$'} />

                        </div>
                        <div className="ml-auto">
                        <p className="mt-3 text-sm">
                      {useNearBy ? <button onClick={this.removeCurrentLocation} type="button" className="text-blue-300 font-medium underline flex items-center space-x-1 ml-auto">
                        <LocationMarkerIcon className="w-4 h-4" /> <span>using current location</span>
                        <XIcon className="w-4 h-4 text-white ml-2" />
                      </button> : <button onClick={this.useCurrentLocation} type="button" className="text-white font-medium underline flex items-center space-x-1 ml-auto">
                        <LocationMarkerIcon className="w-4 h-4" /> <span>use current location</span>
                      </button>}
                    </p>
                        </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppRow>
        <AppRow>
          <div className="flex-1 mt-12">
            {/* <SearchFilters onApply={(filters) => { this.setState({ filters: filters }, () => this.doSearch()) }} filters={this.state.filters} currency={user && user.country ? user.country.currency_symbol : '$'} /> */}
          </div>
        </AppRow>
        <AppRow>
          <div className="flex-1 mt-6">
            {restaurants.length === 0 ? <>
              <p>0 results are found.</p>
            </> : <>
              <div className="max-w-xs mx-auto grid gap-5 xl:grid-cols-3 lg:grid-cols-2 lg:max-w-none">
                {restaurants.map(restaurant => {
                  return <RestaurantItem key={restaurant.id} restaurant={restaurant} />
                })}
              </div>
            </>}
          </div>
        </AppRow>
      </Main>
    )
  }

  onSearch = () => {
    if (this.state.term) {
      this.props.history.replace(`/search/${this.state.term}`)
    } else {
      this.props.history.replace(`/search`)
    }
    this.doSearch();
  }

  componentDidMount() {
    const term = this.props.match?.params?.term;
    if (term) {
      this.setState({ term: term }, () => {
        this.doSearch();
      })
    } else {
      this.doSearch();
    }

    this.searchInput.current.focus();
  }

  doSearch = async () => {
    const { useNearBy, filters, current_location, term } = this.state;

    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost('search', { term: term, nearby: useNearBy, current_location: current_location, filters: filters });

    if (response.status === 'success') {
      this.setState({ restaurants: response.data })
    } else {
      this.MessageRef.current.show(response.message, 'error');
    }

    AppStore.dispatch({ type: 'LOADING', loading: false })
    this.searchInput.current.focus();
  }

  useCurrentLocation = () => {
    const _this = this;

    AppStore.dispatch({ type: 'LOADING', loading: true })

    const geoSuccess = async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      _this.setState({
        current_location: {
          latitude: latitude,
          longitude: longitude
        },
        useNearBy: true
      }, () => {
        AppStore.dispatch({ type: 'LOADING', loading: false })
        this.doSearch();
      })
    }

    const geoError = (error) => {
      this.MessageRef.current.show(error.message, "error");
      AppStore.dispatch({ type: 'LOADING', loading: false })
    }

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    }
  }

  removeCurrentLocation = () => {
    if (this.state.useNearBy) {
      this.setState({
        useNearBy: false
      }, () => {
        this.doSearch();
      })
      return;
    }
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state
  return { loading: loading, user: user }
};

const SearchConnect = connect(mapStateToProps)(props => {
  return <Search {...props} />
})

export default withRouter(SearchConnect)
