import _ from 'lodash'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { InputSelect } from '../components/form/InputSelect'
import { InputText } from '../components/form/InputText'
import { ApiPost, removeData, setData, setError, setMessage } from '../lib/AppHelper'
import { AppStore } from '../lib/AppStore'
import Main from './Main'

const countries_states = require('./../helpers/countries+states.json');

const countries = [];
countries_states.map(c => {
  countries.push({ label: c.name, value: c.iso2 });
  return c;
})

const getStates = (iso2) => {
  const country = _.find(countries_states, (o) => o.iso2 === iso2)
  if (!country) return []
  const states = [{ label: "Select State", value: "" }];
  country.states.map(s => {
    states.push({ label: s.name, value: s.state_code });
    return s;
  });
  return states;
}

const getCountryCode = (iso2) => {
  const country = _.find(countries_states, (o) => o.iso2 === iso2)
  if (!country) return false
  return _.startsWith(country.phone_code, '+') ? country.phone_code : `+${country.phone_code}`;
}

export default class Register extends Component {
  state = {
    params: {
      first_name: "",
      last_name: "",
      country: "US",
      state: "NY",
      dob: null
    },
    states: getStates("US"),
    errors: {}
  }

  MessageRef = React.createRef();

  render() {
    // const { loading } = this.props
    const { params, errors, states } = this.state
    const country_code = getCountryCode(params.country);

    return (
      <Main MessageRef={this.MessageRef} header={false}>
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <h2 className="mt-3 text-3xl font-extrabold text-gray-900">Please enter the<br /> following information</h2>
              </div>

              <div className="mt-3">
                <div className="mb-4">
                  <form action="#" method="POST" className="space-y-4">
                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <InputText label="First Name" onEnter={this.doRegister} error={errors.first_name} attrs={{ value: params.first_name, onChange: (e) => this.onChangeHandler(e, 'first_name') }} />
                      </div>

                      <div className="flex-1">
                        <InputText label="Last Name" onEnter={this.doRegister} error={errors.last_name} attrs={{ value: params.last_name, onChange: (e) => this.onChangeHandler(e, 'last_name') }} />
                      </div>
                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <InputSelect label="Country" attrs={{
                          value: params.country, onChange: (e) => {
                            this.onCountryChange(e);
                          }
                        }} error={errors.country} options={countries} />
                      </div>

                      <div className="flex-1">
                        <InputSelect label="State" attrs={{ value: params.state, onChange: (e) => this.onChangeHandler(e, 'state') }} error={errors.state} options={states} />
                      </div>
                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <InputText label="Zip Code" onEnter={this.doRegister} error={errors.zip} attrs={{ value: params.zip, onChange: (e) => this.onChangeHandler(e, 'zip') }} />
                      </div>
                      <div className="flex-1"></div>
                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <InputText prefix={country_code} label="Cell Phone" onEnter={this.doRegister} error={errors.mobile} attrs={{ value: params.mobile, maxLength: 10, onChange: (e) => this.onChangeHandler({ target: { value: e.target.value.replace(/\D/, '') } }, 'mobile') }} />
                      </div>
                      <div className="flex-1">
                        <InputText prefix={country_code} label="Home Phone" onEnter={this.doRegister} error={errors.phone} attrs={{ value: params.phone, maxLength: 10, onChange: (e) => this.onChangeHandler({ target: { value: e.target.value.replace(/\D/, '') } }, 'phone') }} />
                      </div>
                    </div>

                    <div>
                      <InputText label="Email" onEnter={this.doRegister} error={errors.email} attrs={{ value: params.email, type: 'email', onChange: (e) => this.onChangeHandler(e, 'email') }} />
                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <InputText label="Password" onEnter={this.doRegister} error={errors.password} attrs={{ value: params.password, type: 'password', onChange: (e) => this.onChangeHandler(e, 'password') }} />
                      </div>
                      <div className="flex-1">
                        <InputText label="Confirm Password" onEnter={this.doRegister} error={errors.rpassword} attrs={{ value: params.rpassword, type: 'password', onChange: (e) => this.onChangeHandler(e, 'rpassword') }} />
                      </div>
                    </div>

                    <div>
                      <button
                        onClick={this.doRegister}
                        type="button"
                        className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        Sign Up
                      </button>
                    </div>
                  </form>
                </div>
                <div>

                  <div>
                    <div className="mt-8 relative">
                      <div className="relative flex justify-center">
                        <span className="px-2 bg-white text-gray-500 text-sm">Already have an account?</span>
                      </div>
                      <div className="relative flex justify-center">
                        <Link className="font-medium text-primary-500 hover:text-primary-600" to="/">Log In</Link>
                      </div>
                      <div className="relative flex justify-center mt-4">
                        <Link className="font-medium text-black underline hover:text-primary-600" to="/home">Continue as a Guest</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1 text-center">
            <h4 className="absolute mt-2 font-normal text-white z-10 right-5 text-6xl w-full">For Food Lovers</h4>
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={`${process.env.PUBLIC_URL}/bg.jpeg`}
              alt=""
            />
          </div>
        </div>
      </Main>
    )
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params })
  }

  onCountryChange = (e) => {
    const states = getStates(e.target.value);
    const { params } = this.state;
    _.set(params, 'country', e.target.value);
    _.set(params, 'state', "");
    this.setState({ params: params, states: states })
  }

  doRegister = async () => {
    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost('customer/register', this.state.params);

    if (response.status === 'error') {
      if (_.isEmpty(response.errors)) {
        this.MessageRef.current.show(response.message, 'error');
      } else {
        this.setState({ errors: response.errors })
      }
    } else {
      await setData('access_token', response.data.access_token, async () => {
        await setMessage(response.data.message, () => {
          this.LetsLogin(response.data.access_token);
        });
      });
    }
    AppStore.dispatch({ type: 'LOADING', loading: false })
  }

  LetsLogin = async (token) => {
    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost('customer/me');

    if (response.status === 'success') {
      await setData('@email', response.data.email);
      if (!response.data.country) {
        await setError('Please complete your profile.')
      }
      AppStore.dispatch({ type: 'SIGN_IN', token: token, user: response.data });
    } else {
      await removeData('access_token');
      AppStore.dispatch({ type: 'SIGN_OUT' });
    }
    AppStore.dispatch({ type: 'LOADING', loading: false })
  }
}