import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PencilIcon, XIcon } from '@heroicons/react/outline'
import { InputAvatar } from './form/InputAvatar'
import { InputText } from './form/InputText'
import { AppStore } from '../lib/AppStore'
import { ApiPost } from '../lib/AppHelper'
import { InputSelect } from './form/InputSelect'

const countries_states = require('./../helpers/countries+states.json');

const countries = [];
countries_states.map(c => {
  countries.push({ label: c.name, value: c.iso2 });
  return c;
})

const getStates = (iso2) => {
  const country = _.find(countries_states, (o) => o.iso2 === iso2)
  if (!country) return []
  const states = [{ label: "Select State", value: "" }];
  country.states.map(s => {
    states.push({ label: s.name, value: s.state_code });
    return s;
  });
  return states;
}

const getCountryCode = (iso2) => {
  const country = _.find(countries_states, (o) => o.iso2 === iso2)
  if (!country) return false
  return _.startsWith(country.phone_code, '+') ? country.phone_code : `+${country.phone_code}`;
}

export class EditProfile extends Component {

  state = {
    open: false,
    params: {
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      country: this.props.user.country ? this.props.user.country.iso2 : "US",
      state: this.props.user.state ? this.props.user.state.iso2 : "NY",
      dob: null,
      zip: this.props.user.zip,
      mobile: this.props.user.mobile,
      phone: this.props.user.phone,
      email: this.props.user.email,
    },
    states: getStates(this.props.user.country ? this.props.user.country.iso2 : "US"),
    errors: {},
    avatar: '',
    avatar_uri: ''
  }

  render() {
    const { user } = this.props;

    const { open, params, errors, states } = this.state
    const country_code = getCountryCode(params.country);

    return (
      <>
        <button type="button" onClick={() => this.setState({ open: true })} className="text-sm font-medium text-primary-500 flex items-center hover:underline"><PencilIcon className="w-4 h-4 mr-1" /> Edit</button>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" static className="fixed z-10 inset-0 overflow-hidden" open={open} onClose={this.onClose}>
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-200 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-200 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">Edit Profile</Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={this.onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 relative flex-1 px-4 sm:px-6">
                        <InputAvatar error={errors.avatar} picture={this.state.avatar ? this.state.avatar_uri : user.picture} onChange={(file) => this.onAvatarChange(file)} />
                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <InputText label="First Name" onEnter={this.updateProfile} error={errors.first_name} attrs={{ value: params.first_name, onChange: (e) => this.onChangeHandler(e, 'first_name') }} />
                          </div>

                          <div className="flex-1">
                            <InputText label="Last Name" onEnter={this.updateProfile} error={errors.last_name} attrs={{ value: params.last_name, onChange: (e) => this.onChangeHandler(e, 'last_name') }} />
                          </div>
                        </div>

                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <InputSelect label="Country" attrs={{
                              value: params.country, onChange: (e) => {
                                this.onCountryChange(e);
                              }
                            }} error={errors.country} options={countries} />
                          </div>

                          <div className="flex-1">
                            <InputSelect label="State" attrs={{ value: params.state, onChange: (e) => this.onChangeHandler(e, 'state') }} error={errors.state} options={states} />
                          </div>
                        </div>

                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <InputText label="Zip Code" onEnter={this.updateProfile} error={errors.zip} attrs={{ value: params.zip, onChange: (e) => this.onChangeHandler(e, 'zip') }} />
                          </div>
                          <div className="flex-1"></div>
                        </div>

                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <InputText prefix={country_code} label="Cell Phone" onEnter={this.updateProfile} error={errors.mobile} attrs={{ value: params.mobile, maxLength: 10, onChange: (e) => this.onChangeHandler({ target: { value: e.target.value.replace(/\D/, '') } }, 'mobile') }} />
                          </div>
                          <div className="flex-1">
                            <InputText prefix={country_code} label="Home Phone" onEnter={this.updateProfile} error={errors.phone} attrs={{ value: params.phone, maxLength: 10, onChange: (e) => this.onChangeHandler({ target: { value: e.target.value.replace(/\D/, '') } }, 'phone') }} />
                          </div>
                        </div>

                        <div>
                          <InputText label="Email" onEnter={this.updateProfile} error={errors.email} attrs={{ value: params.email, type: 'email', onChange: (e) => this.onChangeHandler(e, 'email') }} />
                        </div>

                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <InputText label="Password" onEnter={this.updateProfile} error={errors.password} attrs={{ value: params.password, type: 'password', onChange: (e) => this.onChangeHandler(e, 'password') }} />
                          </div>
                          <div className="flex-1">
                            <InputText label="Confirm Password" onEnter={this.updateProfile} error={errors.rpassword} attrs={{ value: params.rpassword, type: 'password', onChange: (e) => this.onChangeHandler(e, 'rpassword') }} />
                          </div>
                        </div>

                        <div>
                          <button
                            onClick={this.updateProfile}
                            type="button"
                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }

  onClose = () => {
    this.setState({
      open: false,
      params: {
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        country: this.props.user.country ? this.props.user.country.iso2 : "US",
        state: this.props.user.state ? this.props.user.state.iso2 : "NY",
        dob: null,
        zip: this.props.user.zip,
        mobile: this.props.user.mobile,
        phone: this.props.user.phone,
        email: this.props.user.email,
      },
      states: getStates(this.props.user.country ? this.props.user.country.iso2 : 'US'),
      errors: {},
      avatar: ''
    });
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params })
  }

  onCountryChange = (e) => {
    const states = getStates(e.target.value);
    const { params } = this.state;
    _.set(params, 'country', e.target.value);
    _.set(params, 'state', "");
    this.setState({ params: params, states: states })
  }

  updateProfile = async () => {
    AppStore.dispatch({ type: 'LOADING', loading: true })

    const data = new FormData();

    if (this.state.avatar) {
      data.append('avatar', this.state.avatar, this.state.avatar.name);
    }

    Object.keys(this.state.params).forEach(key => {
      data.append(key, this.state.params[key]);
    });

    const response = await ApiPost('customer/profile', data);

    if (response.status === 'error') {
      if (_.isEmpty(response.errors)) {
        this.props.MessageRef.current.show(response.message, 'error');
      } else {
        this.setState({ errors: response.errors })
      }
    } else {
      AppStore.dispatch({ type: 'UPDATED', user: response.data.me });
      this.props.MessageRef.current.show(response.data.message, 'success');
      this.setState({ open: false })
    }
    AppStore.dispatch({ type: 'LOADING', loading: false })
  }

  onAvatarChange = (file) => {
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({ avatar: file, avatar_uri: reader.result })
    }.bind(this);
  }
}

export default EditProfile
