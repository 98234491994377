import _ from 'lodash'
import { BookmarkIcon, BookOpenIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/solid'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AppRow, Rating } from '../components/AppComponents'
import { ApiPost, setError } from '../lib/AppHelper'
import { AppStore } from '../lib/AppStore'
import Main from './Main'
import { Lightbox } from "react-modal-image";
import RestaurantReviews from '../components/RestaurantReviews'
import { RatingModal } from '../components/RatingModal'
import { RecommendModal } from '../components/RecommendModal'

export class Detail extends Component {

  MessageRef = React.createRef();

  state = {
    restaurant: null,
    openMenu: false
  }

  RatingModalRef = React.createRef();
  RecommendModalRef = React.createRef();
  ReferModalRef = React.createRef();

  render() {
    const { user } = this.props;
    const { restaurant, openMenu } = this.state;
    if (!restaurant) {
      return <Main MessageRef={this.MessageRef}>
      </Main>
    }

    let user_bookmarked = [];

    if (user) {
      user_bookmarked = _.map(user.bookmarks, 'business.id');
    }

    const bookmarked = _.indexOf(user_bookmarked, restaurant.id) > -1;

    return (
      <Main MessageRef={this.MessageRef} noPadding={true}>
        <AppRow>
          <div className="flex-1 max-w-full">
            <div className="divide-y divide-gray-200">
              <div className="pb-6">
                <div className="bg-gradient-to-r from-primary-700 to-primary-500 h-24 sm:h-20 lg:h-28" />
                <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-15">
                  <div>
                    <div className="-m-1 flex">
                      <div className="inline-flex rounded-lg overflow-hidden border-4 border-white">
                        <div className="flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48" style={{ backgroundImage: `url(${restaurant.cover_url})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 sm:ml-6 sm:flex-1">
                    <div className="flex justify-between">
                      <div>
                        <div className="flex items-center">
                          <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">{restaurant.title}</h3>
                        </div>
                        <p className={`text-sm ${restaurant.open ? 'text-green-500' : 'text-primary-500'}`}>{restaurant.open ? 'Open Now' : 'Closed Now'}</p>
                      </div>
                      <div className="text-right">
                      <button
                        type="button"
                        className="flex-shrink-0 w-full inline-flex items-center justify-center mb-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 lg:flex-1"
                        onClick={this.reviewNow}
                     >
                         Review this vendor
                      </button>                        <Rating size={16} style={{ height: 25, alignItems: 'center', marginBottom: 5 }} value={restaurant.rating} />
                        <RatingModal MessageRef={this.MessageRef} ref={this.RatingModalRef} restaurant={restaurant} onRate={this.ReloadData} />
                        {!restaurant.reviews_count ? <button type="button" onClick={this.rateNow} className="text-sm underline text-gray-400">Rate Now</button> : <RestaurantReviews MessageRef={this.MessageRef} restaurant={restaurant} />}
                      </div>
                    </div>
                    <div className="mt-5 flex flex-wrap space-y-3 lg:space-y-0 lg:space-x-3">
                      <button
                        type="button"
                        className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 lg:flex-1"
                        onClick={this.getVoucher}
                      >
                        View Voucher
                      </button>
                      {openMenu ? <Lightbox
                        small={restaurant.foodmenu_url}
                        large={restaurant.foodmenu_url}
                        alt={restaurant.title}
                        onClose={() => this.setState({ openMenu: false })}
                      /> : false}
                      <div className="flex-1 items-center flex flex-wrap space-x-3">
                        <button
                          type="button"
                          onClick={() => this.setState({ openMenu: true })}
                          className={`flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                        >
                          <BookOpenIcon className="w-5 mr-1" /> Menu
                        </button>
                        <button
                          type="button"
                          onClick={() => this.toggleBookmark(restaurant.id)}
                          className={`flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium ${bookmarked ? 'text-primary-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500' : 'text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'}`}
                        >
                          {bookmarked ? <div className="relative mr-1"><BookmarkIcon className="absolute" /><BookmarkIconSolid stroke className="w-5" /></div> : <BookmarkIcon className="w-5 mr-1" />}
                          {bookmarked ? 'Saved' : 'Save'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 sm:px-0 sm:py-0">
                <dl className="space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-0">
                  <div className="sm:flex sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Description</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
                      <p>
                        {restaurant.description ? restaurant.description : '-'}
                      </p>
                    </dd>
                  </div>
                  <div className="sm:flex sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                      Address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
                      <a href={restaurant.googlemap} rel="noreferrer" target="_blank" className="underline hover:text-primary inline-flex items-center">
                        <LocationMarkerIcon className="w-4 mr-1" /> {restaurant.address_full}
                      </a>
                    </dd>
                  </div>
                  {restaurant.phone ? <>
                    <div className="sm:flex sm:px-6 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                        Phone
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"><a target="_blank" rel="noreferrer" className="hover:text-primary" href={`tel:${restaurant.phone}`}>{restaurant.phone}</a></dd>
                    </div>
                  </> : false}
                  {restaurant.website ? <>
                    <div className="sm:flex sm:px-6 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                        Website
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2"><a target="_blank" rel="noreferrer" className="underline hover:text-primary" href={restaurant.website}>{
                      
                         restaurant.website.replace(/(^\w+:|^)\/\//, '').replace("/", '')
                       
                      }</a></dd>
                    </div>
                  </> : false}

                    <div className="sm:flex sm:px-6 sm:py-5">
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                      Recommend 
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
                        <div className="sm:flex">
                          <div className="flex space-y-3 lg:space-y-0 lg:space-x-3">

                            <button onClick={this.recommendNow} type="button" className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Recommend To A Friend</button>

                            <button onClick={this.referNow} type="button" className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Refer A Vendor</button>

                            <RecommendModal type="recommend" user={this.props.user} MessageRef={this.MessageRef} ref={this.RecommendModalRef} restaurant={restaurant} />
                            <RecommendModal type="refer" user={this.props.user} MessageRef={this.MessageRef} ref={this.ReferModalRef} restaurant={restaurant} />

                          </div>
                        </div>
                      </dd>
                    </div>

                    

                </dl>
              </div>
            </div>
          </div>
        </AppRow>
      </Main>
    )
  }

  async componentDidMount() {
    const id = this.props.match?.params?.id;
    if (!parseInt(id)) {
      await setError('Bad Request')
      this.props.history.push('/');
      return false;
    }
    this.ReloadData(id);
  }

  ReloadData = async (id = 0) => {
    AppStore.dispatch({ type: 'LOADING', loading: true })
    const response = await ApiPost(`business/${id ? id : this.state.restaurant.id}`);
    AppStore.dispatch({ type: 'LOADING', loading: false })
    if (response.status === 'success') {
      this.setState({ restaurant: response.data })
    } else {
      await setError('Bad Request')
      this.props.history.push('/');
      return false;
    }
  }

  toggleBookmark = async (id) => {
    if (!this.props.user) {
      setError('In order to continue, Please login first.', () => {
        this.props.history.replace('/');
      });
      return false;
    }

    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost('business/bookmark', { id: id });
    if (response.status === 'success') {
      const userResponse = await ApiPost(`customer/me`);
      if (userResponse.status === 'success') {
        AppStore.dispatch({ type: 'UPDATED', user: userResponse.data });
      }

      this.MessageRef.current.show(response.data, 'success');
      this.ReloadData();
    } else {
      if (response.code === 401) {
        await setError(response.message);
        this.props.history.push('/');
      } else {
        this.MessageRef.current.show(response.message, 'error');
      }
    }

    AppStore.dispatch({ type: 'LOADING', loading: false })
  }

  getVoucher = async () => {
    if (!this.props.user) {
      setError('In order to continue, Please login first.', () => {
        this.props.history.replace('/');
      });
      return false;
    }

    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost(`get-voucher/${this.state.restaurant.id}`);

    if (response.status === 'success') {
      const userResponse = await ApiPost(`customer/me`);
      if (userResponse.status === 'success') {
        AppStore.dispatch({ type: 'UPDATED', user: userResponse.data });
      }

      window.open(response.data);
    } else {
      this.MessageRef.current.show(response.message, 'error');
    }

    AppStore.dispatch({ type: 'LOADING', loading: false })
  }

  rateNow = async () => {
    if (!this.props.user) {
      setError('In order to continue, Please login first.', () => {
        this.props.history.replace('/');
      });
      return false;
    }

    this.RatingModalRef.current.show();
  }
  reviewNow = async () => {
    if (!this.props.user) {
      setError('In order to continue, Please login first.', () => {
        this.props.history.replace('/');
      });
      return false;
    }
  
    this.RatingModalRef.current.show();
  }

  recommendNow = async () => {
    if (!this.props.user) {
      setError('In order to continue, Please login first.', () => {
        this.props.history.replace('/');
      });
      return false;
    }

    this.RecommendModalRef.current.show();
  }

  referNow = async () => {
    if (!this.props.user) {
      setError('In order to continue, Please login first.', () => {
        this.props.history.replace('/');
      });
      return false;
    }

    this.ReferModalRef.current.show();
  }
  
}


const mapStateToProps = (state) => {
  const { loading, user } = state
  return { loading: loading, user: user }
};

const DetailConnect = connect(mapStateToProps)(props => {
  return <Detail {...props} />
})

export default withRouter(DetailConnect)
