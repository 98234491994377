import { ExclamationIcon, SearchIcon } from '@heroicons/react/outline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { AppRow, RestaurantItem } from '../components/AppComponents'
import Main from './Main'

export class Saved extends Component {

	MessageRef = React.createRef();

	render() {
		const { user } = this.props;
		const { bookmarks } = user;


		return (
			<Main MessageRef={this.MessageRef}>
				<AppRow>
					{bookmarks.length ? <>
						<div className="mt-12 max-w-xs mx-auto grid gap-5 xl:grid-cols-3 lg:grid-cols-2 lg:max-w-none">
							{bookmarks.map(bookmark => {
								return <RestaurantItem key={bookmark.business.id} restaurant={bookmark.business} />
							})}
						</div>
					</> : <>
						<div className="text-center flex-1">
							<ExclamationIcon className="mx-auto h-10 w-10 text-gray-400" />
							<h3 className="mt-2 text-base font-medium text-gray-900">No saved restaurant</h3>
							<p className="mt-1 text-base text-gray-500">Let's find some exiciting deals at your favorite restaurant(s).</p>
							<div className="mt-6">
								<Link
									to="/search"
									className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400"
								>
									<SearchIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
									Search Now
								</Link>
							</div>
						</div>
					</>}
				</AppRow>
			</Main>
		)
	}
}

const mapStateToProps = (state) => {
	const { loading, user } = state
	return { loading: loading, user: user }
};

const SavedConnect = connect(mapStateToProps)(props => {
	return <Saved {...props} />
})

export default withRouter(SavedConnect)
