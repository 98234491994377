import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AppRow } from '../components/AppComponents'
import EditProfile from '../components/EditProfile'
import { ApiPost, setError } from '../lib/AppHelper'
import { AppStore } from '../lib/AppStore'
// import { AppStore } from '../lib/AppStore'
import Main from './Main'

export class Profile extends Component {

	MessageRef = React.createRef();

	render() {
		const { user } = this.props;
		const { vouchers } = user;

		return (
			<Main MessageRef={this.MessageRef}>
				<AppRow>
					<div className="flex-1 max-w-full">
						<div className="lg:max-w-lg px-8 py-4 mt-16 bg-white rounded-lg shadow-lg">
							<div className="flex justify-center -mt-16 md:justify-end">
								<img className="object-cover w-24 h-24 rounded-full" alt="Testimonial avatar" src={user.picture} />
							</div>

							<h2 className="mt-2 text-2xl font-semibold text-gray-800 md:mt-0">{user.name}</h2>

							<p className="mt-4 text-gray-400">Account Information</p>
							<p className="mt-1 text-gray-600">Email: {user.email}</p>
							{user.mobile ? <p className="mt-1 text-gray-600">Cell Phone: {user.mobile}</p> : false}
							{user.phone ? <p className="mt-1 text-gray-600">Home Phone: {user.phone}</p> : false}
							{user.state ? <p className="mt-1 text-gray-600">State: {user.state.name}</p> : false}
							{user.country ? <p className="mt-1 text-gray-600">Country: {user.country.name}</p> : false}
							<div className="flex justify-end mt-4">
								<EditProfile MessageRef={this.MessageRef} user={user} />
							</div>
						</div>
						<div className="mt-10 lg:max-w-lg">
							<p className="mb-2 text-gray-600">History</p>
							<div className="shadow-lg rounded-lg overflow-hidden">
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Restaurant
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Date
											</th>
											<th scope="col" className="relative px-3 py-3">
												<span className="sr-only">Edit</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{vouchers.map((voucher, personIdx) => (
											<tr key={voucher.id} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
												<td className="px-3 py-4 whitespace-normal text-sm font-medium text-gray-900">{voucher.food_business.title}</td>
												<td className="px-3 py-4 whitespace-v text-sm text-gray-500">{voucher.deal_date}</td>
												<td className="px-3 py-4 whitespace-normal text-right text-sm font-medium">
													{voucher.status ? <span className="text-green-500">
														Redeemed On<strong className="ml-1">{voucher.redeemed_on_formatted}</strong>
													</span> : <button type="button" onClick={() => this.getVoucher(voucher.food_business.id)} className="text-primary-500 hover:text-primary-600 hover:underline">
														View Voucher
													</button>}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</AppRow>
			</Main>
		)
	}

	getVoucher = async (id) => {
		if (!this.props.user) {
			setError('In order to continue, Please login first.', () => {
				this.props.history.replace('/');
			});
			return false;
		}

		AppStore.dispatch({ type: 'LOADING', loading: true })

		const response = await ApiPost(`get-voucher/${id}`);

		if (response.status === 'success') {
			const userResponse = await ApiPost(`customer/me`);
			if (userResponse.status === 'success') {
				AppStore.dispatch({ type: 'UPDATED', user: userResponse.data });
			}

			window.open(response.data);
		} else {
			this.MessageRef.current.show(response.message, 'error');
		}

		AppStore.dispatch({ type: 'LOADING', loading: false })
	}
}

const mapStateToProps = (state) => {
	const { loading, user } = state
	return { loading: loading, user: user }
};

const ProfileConnect = connect(mapStateToProps)(props => {
	return <Profile {...props} />
})

export default withRouter(ProfileConnect)
