import React, { Component, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Rating, AppRow } from './AppComponents';
import { ApiPost } from '../lib/AppHelper';
import { AppStore } from '../lib/AppStore';

export class RestaurantReviews extends Component {

  state = {
    open: false,
    restaurant: null
  }

  onOpen = async () => {
    const loaded = await this.LoadReviews();
    if (!loaded) return false;
    this.setState({ open: true })
  }

  render() {
    const { open } = this.state;
    const { restaurant } = this.props;
    const restaurant_reviews = this.state.restaurant?.reviews ? this.state.restaurant?.reviews : [];

    return (
      <>
        <button type="button" onClick={this.onOpen} className="text-sm underline text-gray-400">{restaurant.reviews_count} Ratings</button>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" static className="fixed z-10 inset-0 overflow-hidden" open={open} onClose={() => this.setState({ open: false })}>
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-200 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-200 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">{`Ratings - ${restaurant.title}`}</Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => this.setState({ open: false })}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 border-t pt-3 relative px-4 sm:px-6">
                        <p className="text-4xl mb-3 text-center">{restaurant.rating}</p>
                        <Rating value={restaurant.rating} size={24} style={{ margin: 'auto' }} />
                        <p className="text-gray-500 mt-3 text-center">Based on {restaurant.reviews_count} ratings</p>
                      </div>
                      <div className="flex-1 border-t mt-4">
                        {restaurant_reviews.map(review => {
                          return <AppRow key={review.id} className="border-b">
                            <div className="flex-1 px-6 py-2 flex justify-start">
                              <div>
                                <img alt={review.customer.name} src={review.customer.picture} className="h-10 border w-10 rounded-full" />
                              </div>
                              <div style={{ flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                                <p className="truncate">{review.customer.name}</p>
                                <p className="text-gray-400 text-sm">{review.when}</p>
                              </div>
                              <div>
                                <div className="flex items-center">
                                  <Rating value={review.rating} size={15} />
                                  <p className="text-sm ml-2">{review.rating_value}</p>
                                </div>
                              </div>
                            </div>
                          </AppRow>
                        })}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }

  LoadReviews = async () => {
    AppStore.dispatch({ type: 'LOADING', loading: true })
    const response = await ApiPost(`business/reviews`, { id: this.props.restaurant.id });
    if (response.status === 'success') {
      this.setState({ restaurant: response.data })
      AppStore.dispatch({ type: 'LOADING', loading: false })
      return true;
    } else {
      this.props.MessageRef.current.show(response.message, 'error');
      AppStore.dispatch({ type: 'LOADING', loading: false })
      return false;
    }
  }
}

export default RestaurantReviews
