import React from 'react'
import { connect } from 'react-redux';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';
import { setError } from './AppHelper';
// import { useRouteMatch } from 'react-router-dom';

const mapStateToProps = (state) => {
    const { token, user } = state
    return { token: token, user: user }
};

export default connect(mapStateToProps)(({ children, ...rest }) => {

    const is_logout = useRouteMatch('/logout')?.isExact;

    return <Route {...rest} render={({ location }) => {
        if (rest.token) {
            return children
        } else {
            if (!is_logout) {
                setError("In order to continue, Please login first.")
            }
            return <Redirect to={{ pathname: "/" }} />
        }
    }} />
})