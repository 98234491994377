import { SearchIcon } from '@heroicons/react/outline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { AppRow, RestaurantItem } from '../components/AppComponents'
import { ApiPost } from '../lib/AppHelper'
import Main from './Main'

export class Home extends Component {

	MessageRef = React.createRef();

	state = {
		restaurants: [],
		getting_recent: false,
		term: ""
	}

	render() {
		// const { user } = this.props;

		const { getting_recent, restaurants, term } = this.state;

		return (
			<Main MessageRef={this.MessageRef}>
				<AppRow>
					<div className="flex-1 rounded-lg bg-primary" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=714&q=80)', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
						<div style={{ backgroundColor: 'rgba(0,0,0,.5)' }} className="px-6 py-6 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
							<div className="xl:w-0 xl:flex-1">
								<h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
									Explore Local Food Vendors
								</h2>
								<p className="mt-3 max-w-3xl text-lg leading-6 text-gray-200">
									Search near by food vendors.
								</p>
							</div>
							<div className="mt-8 sm:w-full sm:max-w-sm xl:mt-0 xl:ml-8">
								<div className="sm:flex">
									<div className="flex-1 relative">
										<label className="sr-only">
											Search
										</label>
										<input
											type="text"
											value={term}
											onChange={(e) => this.setState({ term: e.target.value })}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													this.onSearch()
												}
											}}
											className="w-full border-white px-5 pr-14 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-400 rounded-md"
											placeholder="Search"
										/>
										<button
											type="button"
											onClick={this.onSearch}
											className="absolute top-1.5 rounded-md right-2 w-full self-start text-gray-500 flex items-center justify-center px-4 bg-transparent py-2 border border-transparent text-base font-medium focus:bg-primary focus:text-white focus:outline-none focus:ring-0 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
										>
											<SearchIcon className="w-5" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</AppRow>
				<AppRow>
					<div style={{ backgroundColor: '#bc3530' }} className="flex-1 mt-12 max-w-full rounded-lg">
						<div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
							<div>
								<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
									<span className="block text-white mb-2">Daily Vouchers</span>
									<span className="block text-white text-2xl">Daily deals that are available today.</span>
								</h2>
							</div>
							<div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
								<div className="inline-flex rounded-full shadow">
									<Link
										to="/search"
										className="inline-flex items-center justify-center px-5 py-3 border border-transparent rounded-full text-base font-medium text-primary-500 bg-white hover:bg-gray-100"
									>
										View Deals
									</Link>
								</div>
							</div>
						</div>
					</div>
				</AppRow>
				<AppRow>
					<div className="flex-1 mt-12">
						<p className="mb-2 text-gray-600">Recent Eats</p>
						{getting_recent ? <>
							<svg className="animate-spin h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
							</svg>
						</> : <>
							<div className="max-w-xs mx-auto grid gap-5 xl:grid-cols-3 lg:grid-cols-2 lg:max-w-none">
								{restaurants.map(restaurant => {
									return <RestaurantItem key={restaurant.id} restaurant={restaurant} />
								})}
							</div>
						</>}
					</div>
				</AppRow>
			</Main>
		)
	}

	componentDidMount() {
		this.GetRecents();
	}

	GetRecents = () => {
		// const location = await this.getLocations();

		this.setState({ getting_recent: true }, async () => {
			const response = await ApiPost('recents', {});
			if (response.status === 'success') {
				this.setState({ getting_recent: false, restaurants: response.data })
			}
		})
	}

	onSearch = () => {
		if (this.state.term) {
			this.props.history.push(`/search/${this.state.term}`)
		} else {
			this.props.history.push(`/search`)
		}
	}
}

const mapStateToProps = (state) => {
	const { loading, user } = state
	return { loading: loading, user: user }
};

const HomeConnect = connect(mapStateToProps)(props => {
	return <Home {...props} />
})

export default withRouter(HomeConnect)
