import React from 'react'
import { Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { BookmarkIcon, HomeIcon, UserIcon, XIcon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid';
import { Link, useRouteMatch } from 'react-router-dom';
import Logo from './Logo';

const LogoLink = () => {
   const is_login = useRouteMatch('/')?.isExact;
   const is_register = useRouteMatch('/register')?.isExact;

   let link = "/home";
   if (is_login || is_register) link = "/";
   return (<Link to={link}>
      <Logo color="white-r" width="full" />
   </Link>)
}

const navigation = [
   { name: 'Home', link: '/home', icon: HomeIcon },
   { name: 'Search', link: '/search', icon: SearchIcon },
   { name: 'Profile', link: '/profile', icon: UserIcon },
   { name: 'Saved', link: '/favourites', icon: BookmarkIcon }
];

function classNames(...classes) {
   return classes.filter(Boolean).join(' ')
}

const SidebarItemLink = ({ item, view = 'mobile' }) => {
   const current = useRouteMatch(item.link);

   if (view === 'mobile') {
      return (<Link
         to={item.link}
         className={classNames(
            current !== null ? 'bg-primary-800 text-white' : 'text-primary-100 hover:bg-primary-500',
            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
         )}
      >
         <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-primary-200" aria-hidden="true" />
         {item.name}
      </Link>)
   } else {
      return (<Link
         to={item.link}
         className={classNames(
            current !== null ? 'bg-primary-600 text-white' : 'text-primary-100 hover:bg-primary-500',
            'group flex items-center px-2 py-2 font-medium rounded-md'
         )}
      >
         <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-primary-200" aria-hidden="true" />
         {item.name}
      </Link>)
   }
}

export default function AppSidebar({ sidebarOpen, setSidebarOpen }) {
   return (
      <Fragment>
         <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
               as="div"
               static
               className="fixed inset-0 flex z-40 md:hidden"
               open={sidebarOpen}
               onClose={setSidebarOpen}
            >
               <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
               >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
               </Transition.Child>
               <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
               >
                  <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-500">
                     <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                     >
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                           <button
                              type="button"
                              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                              onClick={() => setSidebarOpen(false)}
                           >
                              <span className="sr-only">Close sidebar</span>
                              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                           </button>
                        </div>
                     </Transition.Child>
                     <div className="flex-shrink-0 flex items-center px-4">
                        <LogoLink />
                     </div>
                     <div className="mt-5 flex-1 h-0 overflow-y-auto">
                        <nav className="px-2 space-y-1">
                           {navigation.map((item) => (
                              <SidebarItemLink key={item.name} item={item} />
                           ))}
                        </nav>
                     </div>
                  </div>
               </Transition.Child>
               <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
               </div>
            </Dialog>
         </Transition.Root>

         <div className="hidden bg-primary-500 md:flex md:flex-1 max-w-xs">
            <div className="flex flex-col w-full">
               <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                     <LogoLink />
                  </div>
                  <div className="mt-5 flex-1 flex flex-col">
                     <nav className="flex-1 px-2 space-y-1">
                        {navigation.map((item) => (
                           <SidebarItemLink view="desktop" key={item.name} item={item} />
                        ))}
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   )
}

