import React, { Component, Fragment } from 'react'
import { MenuAlt2Icon, UserIcon } from '@heroicons/react/outline'
import UserMenu from '../components/UserMenu'
import AppSpinner from '../components/AppSpinner'
import { connect } from 'react-redux'
import { AppMessage } from '../components/AppMessage'
import { Link } from 'react-router-dom'
import { getError, getMessage } from '../lib/AppHelper'
import AppSidebar from '../components/AppSidebar'
// import { AppStore } from '../lib/AppStore'

export class Main extends Component {

  loadingRef = React.createRef();

  state = {
    sidebarOpen: false
  }

  render() {
    const { loading, MessageRef = null, user, header = true, children, noPadding = false } = this.props;
    const { sidebarOpen } = this.state;

    const mainStyle = loading ? { pointerEvents: 'none' } : {};

    return (
      <Fragment>
        {loading ? <div tabIndex={0} ref={this.loadingRef} className="fixed w-screen h-screen bg-primary-50 z-50 opacity-60 flex items-center justify-center">
          <AppSpinner />
        </div> : false}
        {MessageRef !== null ? <AppMessage ref={MessageRef} /> : false}
        {header ? <div style={mainStyle} className="h-screen flex overflow-hidden bg-gray-100">

          <AppSidebar sidebarOpen={sidebarOpen} setSidebarOpen={(state) => this.setState({ sidebarOpen: state })} />

          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
              <button
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => this.setState({ sidebarOpen: true })}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 px-4 flex justify-between">
                <div className="flex-1 flex">

                </div>
                <div className="ml-4 flex items-center md:ml-6">
                  {user ? <UserMenu /> : <Link to="/profile"
                    className={`h-8 w-8 rounded-full bg-gray-200 flex justify-center items-center text-gray-600`}
                  >
                    <UserIcon width={14} />
                  </Link>}
                </div>
              </div>
            </div>

            <main className="flex-1 relative overflow-y-auto focus:outline-none">
              <div className={noPadding ? `` : `py-6`}>
                <div className={`max-w-7xl mx-auto${noPadding ? `` : ` px-4 sm:px-6 md:px-8`}`}>
                  <div className={noPadding ? `` : `py-4`}>
                    {children}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div> : children}
      </Fragment>
    )
  }

  componentDidMount() {
    this.checkMessages();
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.loading !== this.props.loading && this.props.loading) {
      this.loadingRef.current.focus();
    }
  }

  checkMessages = async () => {
    const error = await getError();
    if (error && this.props.MessageRef?.current) {
      this.props.MessageRef?.current.show(error, 'error');
    }

    const message = await getMessage();
    if (message && this.props.MessageRef?.current) {
      this.props.MessageRef?.current.show(message, 'success');
    }
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state
  return { loading: loading, user: user }
};

export default connect(mapStateToProps)(props => {
  return <Main {...props} />
})