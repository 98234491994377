import { createStore } from 'redux';
import { CONFIG } from './AppHelper';

const INITIAL_STATE = {
    user: null,
    token: null,
    config: CONFIG,
    loading: false,
    loading_count: 0,
    term: ''
};

function AppReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'RESTORE_TOKEN':
            return {
                ...state,
                token: action.token
            };
        case 'SIGN_IN':
            return {
                ...state,
                token: action.token,
                user: action.user,
            };
        case 'UPDATED':
            return {
                ...state,
                user: action.user,
            };
        case 'SEARCH':
            return {
                ...state,
                term: action.term,
            };
        case 'SIGN_OUT':
            return {
                ...state,
                token: null,
                user: null,
            };
        case 'LOADING':
            let status = action.loading
            let count = action.loading ? state.loading_count + 1 : state.loading_count - 1

            if (status === false && count > 0) {
                status = true
            }

            return {
                ...state,
                loading: status,
                loading_count: count
            };
        default:
            return state;
    }
};

export const AppStore = createStore(AppReducer)