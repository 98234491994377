import { Dialog, Transition } from '@headlessui/react'
import React, { Component } from 'react'
import { Fragment } from 'react'
import { ApiPost } from '../lib/AppHelper'
import { AppStore } from '../lib/AppStore'
import SelectRating from './form/SelectRating'

export class RatingModal extends Component {

  state = {
    open: false,
    rate: 4
  }

  show = async () => {
    if (this.props.id) {
      AppStore.dispatch({ type: 'LOADING', loading: true })

      const response = await ApiPost('business/get-review', { id: this.props.id });

      if (response.status === 'error') {
        this.props.MessageRef.current.show(response.message, 'error');
        AppStore.dispatch({ type: 'LOADING', loading: false })
        return false;
      } else {
        this.setState({ id: this.props.restaurant.id, rate: response.data.rating, open: true, review_id: this.props.id });
        AppStore.dispatch({ type: 'LOADING', loading: false })
      }
    } else {
      this.setState({ open: true, id: this.props.restaurant.id, rate: 4, review_id: 0 })
    }
  }

  hide = () => {
    this.setState({ open: false })
  }

  cancelButtonRef = React.createRef();

  render() {
    const { open, rate } = this.state;

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={this.cancelButtonRef}
          open={open}
          onClose={this.hide}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Select Your Rating
                    </Dialog.Title>
                    <div className="mt-4 justify-center flex">
                      <SelectRating value={rate} onChange={(v) => this.setState({ rate: v })} />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-500 text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:col-start-2 sm:text-sm"
                    onClick={this.submit}
                  >
                    Submit Rating
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={this.hide}
                    ref={this.cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  submit = async () => {
    AppStore.dispatch({ type: 'LOADING', loading: true })

    const response = await ApiPost('business/review', { id: this.state.id, rating: this.state.rate, remark: '', review_id: this.state.review_id });
    if (response.status === 'error') {
      this.props.MessageRef.current.show(response.message, 'error');
    } else {
      this.props.MessageRef.current.show(response.data, 'success');
    }
    this.hide();
    this.props.onRate();

    AppStore.dispatch({ type: 'LOADING', loading: false })
  }
}

export default React.forwardRef((props, ref) => <RatingModal innerRef={ref} {...props} />);
