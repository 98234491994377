import { StarIcon } from '@heroicons/react/solid';
import React from 'react'

export default function SelectRating({ value, onChange }) {

   const size = 40;

   return (
      <div style={{ width: (size * 5 + 55) }} className="flex justify-between">
         {[0, 1, 2, 3, 4].map(index => {
            let width = 100;

            if (value >= index && value < (index + 1)) {
               width = ((value - index) / 1) * 100;
            } else if (value < index) {
               width = 0;
            }

            return <button type="button" onClick={() => onChange(index + 1)} key={index} style={{ width: size, height: size, position: 'relative' }}>
               <StarIcon style={{ width: size, height: size, position: 'absolute' }} className="text-gray-400" />
               <div style={{ width: `${width}%`, overflow: 'hidden', zIndex: 1, position: 'relative' }}>
                  <StarIcon style={{ width: size, height: size }} className="text-primary" />
               </div>
            </button>
         })}
      </div>
   )
}
