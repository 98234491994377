import React from 'react'

export default function Logo({ width = 56, color = '' }) {

    return (
        <div className={`w-${width}`}>
            <img alt="" src={`${process.env.PUBLIC_URL}/logo${color ? `-${color}` : ``}.png`} />
        </div>
    )
}
