import React from 'react'
import { connect } from 'react-redux'
import InputLabel from './InputLabel';

export function InputSelect({ loading = false, label = "", attrs = {}, options = [], error = '' }) {

  let loading_disabled = loading ? { disabled: true } : {};

  return (
    <div className="mb-5">
      <InputLabel label={label} />
      <div className="mt-2 relative">
        <select className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
          {...attrs}
          {...loading_disabled}
        >
          {options.map((option, index) => {
            return <option key={index} value={option.value}>{option.label}</option>
          })}
        </select>
      </div>
      {error ? <p className="mt-2 text-sm text-red-500">{error.join(' ')}</p> : false}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { loading } = state
  return { loading: loading }
};

export default connect(mapStateToProps)(props => {
  return <InputSelect {...props} />
})