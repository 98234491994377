import React, { Component } from 'react'
import marked from 'marked'
import _ from 'lodash';
import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';

export class AppMessage extends Component {

   messageRef = React.createRef();

   state = {
      open: false,
      message: 'A simple alert with text and a right icon',
      type: 'error'
   }

   show = (message, type = 'error') => {
      this.setState({
         type: type,
         message: message,
         open: true
      }, () => {
         this.messageRef.current.focus();
         setTimeout(() => {
            this.hide();
         }, 4000);
      })
   }

   hide = () => {
      this.setState({
         open: false
      })
   }

   render() {
      let { open, message, type } = this.state;

      if (!open) return false

      message = _.isString(message) ? message : '';

      /* return (
         <div ref={this.messageRef} tabIndex={0} className={`focus:outline-none focus:ring-0 rounded-md mx-auto sm:w-3/4 md:w-2/4 fixed inset-x-0 top-10 ${type === 'success' ? 'bg-green-50' : 'bg-red-50'} p-4 z-50`}>
            <div className="flex">
               <div className="flex-shrink-0">
                  {type === 'success' ? <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" /> : <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
               </div>
               <div className="ml-3">
                  <p className={`text-sm font-medium ${type === 'success' ? 'text-green-800' : 'text-red-800'}`} dangerouslySetInnerHTML={{ __html: marked(message) }}></p>
               </div>
               <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                     <button
                        type="button"
                        onClick={this.hide}
                        className={type === 'success' ? `inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600` : `inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600`}
                     >
                        <span className="sr-only">Dismiss</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                     </button>
                  </div>
               </div>
            </div>
         </div>
      )
 */
      return (
         <>
            <div
               aria-live="assertive"
               ref={this.messageRef} tabIndex={0} className="focus:outline-none focus:ring-0 fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
               <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                  <Transition
                     show={open}
                     as={Fragment}
                     enter="transform ease-out duration-300 transition"
                     enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                     enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                     leave="transition ease-in duration-100"
                     leaveFrom="opacity-100"
                     leaveTo="opacity-0"
                  >
                     <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="p-4">
                           <div className="flex items-start">
                              <div className="flex-shrink-0">
                                 {type === 'success' ? <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" /> : <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                              </div>
                              <div className="ml-3 w-0 flex-1 pt-0.5">
                                 <p className="text-sm font-medium text-gray-900">{_.upperFirst(type)}</p>
                                 <p className="mt-1 text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: marked(message) }}></p>
                              </div>
                              <div className="ml-4 flex-shrink-0 flex">
                                 <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={this.hide}
                                 >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Transition>
               </div>
            </div>
         </>
      )
   }


}

export default React.forwardRef((props, ref) => <AppMessage innerRef={ref} {...props} />);