import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FilterIcon, XIcon } from '@heroicons/react/outline'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select from 'react-select'

const selectCustomStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 40,
    border: 'none',
    backgroundColor: '#f3f4f6',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '4px 10px',
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 20,
    backgroundColor: '#A03935',
    color: '#fff',
    paddingLeft: "10px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    borderRadius: 20,
    color: '#fff',
    backgroundColor: '#A03935',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    borderRadius: 20,
    color: '#fff',
    paddingRight: "10px",
    backgroundColor: '#A03935',
    "&:hover": {
      color: '#fff',
      backgroundColor: '#A03935',
    }
  })
}

export class SearchFilters extends Component {

  state = {
    open: false,
    food_styles: [],
    food_price: null,
    food_venues: [],
    food_conveniences: [],
  }

  render() {

    const { open } = this.state;

    const { config, currency } = this.props;
    const { food_styles, food_price, food_venues, food_conveniences } = this.state;

    const styles = [];
    const prices = [];
    const venues = [];
    const conveniences = [];

    const select_food_styles = [];

    _.forEach(config.food_styles, ((food_style, food_style_id) => {
      styles.push({
        label: food_style, value: parseInt(food_style_id)
      });
      if (_.indexOf(food_styles, parseInt(food_style_id)) > -1) {
        select_food_styles.push({
          label: food_style, value: parseInt(food_style_id)
        });
      }
    }))

    _.forEach(config.food_venues, ((food_venue, food_venue_id) => {
      venues.push({
        title: food_venue, value: parseInt(food_venue_id)
      });
    }))

    _.forEach(config.food_prices, ((food_price_item, food_price_id) => {
      const title = _.replace(food_price_item, /\$/g, currency);
      prices.push({
        title: title, value: parseInt(food_price_id)
      });
    }))

    _.forEach(config.food_conveniences, ((food_convenience_item, food_convenience_id) => {
      const title = _.replace(food_convenience_item, /\$/g, currency);
      conveniences.push({
        title: title, value: parseInt(food_convenience_id)
      });
    }))

    const count = this.filterApplied();

    return (
      <>
        <div className="flex   space-x-4">
          <button onClick={this.onOpen} className="flex items-center text-white  px-2 rounded-full"><FilterIcon className="w-4 mr-1" />{count ? `${count} ` : false}Filters</button>
          {count ? <>
            <span className="border-l"></span>
            <button onClick={this.clear} className="text-gray-500 hover:underline focus:underline hover:text-primary focus:text-primary">Clear All</button>
          </> : false}
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" static className="fixed z-10 inset-0 overflow-hidden" open={open} onClose={() => this.setState({ open: false })}>
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-200 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-200 sm:duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">Filters</Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => this.setState({ open: false })}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 relative flex-1 px-4 sm:px-6">
                        <p className="text-primary mb-2">Food Style</p>
                        <Select value={select_food_styles} onChange={this.onChangeSelectFilter} className="food-style-select" isMulti={true} options={styles} styles={selectCustomStyles} />
                        <br />
                        <p className="text-primary mb-2">Food Prices</p>
                        <div className="flex">
                          {prices.map((price) => {
                            return <button onClick={() => {
                              if (food_price === price.value) {
                                this.onChangeFilter('food_price', null)
                              } else {
                                this.onChangeFilter('food_price', price.value)
                              }
                            }} className={[`px-4 py-2 m-1 rounded-full`, food_price === price.value ? `bg-primary-500 text-white` : `bg-gray-100`].join(' ')} key={price.value}>{price.title}</button>
                          })}
                        </div>
                        <br />
                        <p className="text-primary mb-2">Food Venue</p>
                        <div className="flex flex-wrap">
                          {venues.map((venue) => {
                            return <button onClick={() => this.onChangeFilterMultiple('food_venues', venue.value)} className={[`px-4 py-2 whitespace-nowrap m-1 rounded-full`, _.indexOf(food_venues, venue.value) > -1 ? `bg-primary-500 text-white` : `bg-gray-100`].join(' ')} key={venue.value}>{venue.title}</button>
                          })}
                        </div>
                        <br />
                        <p className="text-primary mb-2">Dining Options</p>
                        <div className="flex flex-wrap">
                          {conveniences.map((convenience) => {
                            return <button onClick={() => this.onChangeFilterMultiple('food_conveniences', convenience.value)} className={[`px-4 py-2 whitespace-nowrap m-1 rounded-full`, _.indexOf(food_conveniences, convenience.value) > -1 ? `bg-primary-500 text-white` : `bg-gray-100`].join(' ')} key={convenience.value}>{convenience.title}</button>
                          })}
                        </div>
                        <br />
                        <div className="flex justify-end space-x-4">
                          <button onClick={this.clear} type="button" className="flex justify-center py-2 px-6 border rounded-full shadow-sm font-medium border-primary-500 hover:bg-primary-500 hover:text-white text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Clear</button>
                          <button onClick={this.apply} type="button" className="flex justify-center py-2 px-6 border border-transparent rounded-full shadow-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Apply</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }

  onOpen = () => {
    this.setState({
      open: true,
      food_styles: this.props.filters.food_styles,
      food_price: this.props.filters.food_price,
      food_venues: this.props.filters.food_venues,
      food_conveniences: this.props.filters.food_conveniences,
    })
  }

  onChangeFilter = (key, value) => {
    const filter = {};
    filter[key] = value;
    this.setState(filter);
  }

  onChangeFilterMultiple = (key, selected) => {
    const vals = _.get(this.state, key, []);
    let newValue = _.cloneDeep(vals);

    if (_.indexOf(vals, selected) > -1) {
      newValue.splice(_.indexOf(vals, selected), 1);
    } else {
      newValue.push(selected);
    }
    this.onChangeFilter(key, newValue);
  }

  onChangeSelectFilter = (value) => {
    const newValue = [];
    value.map((item) => {
      newValue.push(item.value);
      return item;
    })
    this.onChangeFilter('food_styles', newValue);
  }

  apply = () => {
    this.setState({ open: false }, () => {
      this.props.onApply({
        food_styles: this.state.food_styles,
        food_price: this.state.food_price,
        food_venues: this.state.food_venues,
        food_conveniences: this.state.food_conveniences,
      });
    })
  }

  clear = () => {
    this.setState({
      open: false,
      food_styles: [],
      food_price: null,
      food_venues: [],
      food_conveniences: [],
    }, () => {
      this.props.onApply({
        food_styles: [],
        food_price: null,
        food_venues: [],
        food_conveniences: [],
      });
    })
  }

  filterApplied = () => {
    let count = 0;
    if (this.props.filters.food_styles.length) {
      count += this.props.filters.food_styles.length
    }

    if (this.props.filters.food_price) {
      count += 1
    }

    if (this.props.filters.food_venues.length) {
      count += this.props.filters.food_venues.length
    }

    if (this.props.filters.food_conveniences.length) {
      count += this.props.filters.food_conveniences.length
    }

    return count;
  }
}

const mapStateToProps = (state) => {
  const { config } = state
  return { config: config }
};

const SearchConnect = connect(mapStateToProps)(props => {
  return <SearchFilters {...props} />
})

export default withRouter(SearchConnect)