import { StarIcon } from '@heroicons/react/solid';
import React from 'react';
import { Link } from 'react-router-dom';

export const AppRow = ({ children, className = "" }) => {
  return <div className={["flex flex-wrap flex-col md:flex-row space-x-0 md:space-x-5 space-y-5 md:space-y-0", className].join(" ")}>
    {children}
  </div>
}

export const AppHeading = ({ children, color = "black", className = "", size = "2xl", font = "medium" }) => {
  return <h3 className={[`text-${size} font-${font} text-${color}`, className].join(" ")}>{children}</h3>
}

export const AppCard = ({ children, title = "", className = "", attrs = {} }) => {
  return <div className={[`rounded-xl bg-primary-50 border border-primary p-4`, className].join(" ")} {...attrs}>
    {title ? <h5 className={`text-base mb-2`}>{title}</h5> : false}
    {children}
  </div>
}

export const AppSidebarMenu = ({ children }) => {
  return <div className="flex md:flex-col flex-row">
    {children}
  </div>
}

export const RestaurantItem = ({ restaurant, onClick = () => { } }) => {
  return <Link to={`foods/${restaurant.id}`} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div className="flex-shrink-0">
      <img className="h-48 w-full object-cover" src={restaurant.cover_url} alt="" />
    </div>
    <div className="flex-1 bg-white p-6 flex justify-between">
      <div className="flex-1">
        <div>
          <p className="text-xl font-semibold text-gray-900">{restaurant.title}</p>
          <p className="mt-3 text-base text-gray-500">{restaurant.address_full}</p>
        </div>
      </div>
      <div className="pl-3">
        <Rating size={14} style={{ height: 25, alignItems: 'center', marginBottom: 5 }} value={restaurant.rating} />
        <p className={`${restaurant.open ? 'text-success-500' : 'text-primary'} text-sm`}>{restaurant.open ? 'Open Now' : 'Closed Now'}</p>
      </div>
    </div>
  </Link>
}

export const Rating = ({ size = 10, value, style = {} }) => {
  return <div style={{ ...style, width: size * 5 + 10 }} className="flex justify-between">
    {[0, 1, 2, 3, 4].map(index => {
      let width = 100;

      if (value >= index && value < (index + 1)) {
        width = ((value - index) / 1) * 100;
      } else if (value < index) {
        width = 0;
      }

      return <div key={index} style={{ width: size, height: size, position: 'relative' }}>
        <StarIcon style={{ height: size, width: size, position: 'absolute' }} className="text-gray-300" />
        <div style={{ width: `${width}%`, overflow: 'hidden', zIndex: 1, position: 'relative' }}>
          <StarIcon style={{ height: size, width: size }} className="text-primary" />
        </div>
      </div>
    })}
  </div>
}