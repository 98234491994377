import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ChevronDownIcon } from '@heroicons/react/outline'

const navigation = [
  { title: 'Profile', 'link': '/profile' },
  { title: 'Saved', 'link': '/favourites' },
  { title: 'Logout', 'link': '/logout' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UserMenu() {
  const user = useSelector(state => state.user)

  return <Menu as="div" className="ml-3 relative">
    {({ open }) => (
      <>
        <div>
          <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
            <img
              className="h-8 w-8 rounded-full"
              src={user?.picture}
              alt=""
            />
            <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
              <span className="sr-only">Open user menu for </span>{user.name}
            </span>
            <ChevronDownIcon
              className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {navigation.map((item, index) => (
              <Menu.Item key={item.title}>
                {({ active }) => (
                  <Link key={index} to={item.link} className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}>
                    {item.title}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
}
