import React from 'react';
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { AppStore } from './lib/AppStore';

import PrivateRoute from './lib/PrivateRoute';
import AppRoutes from './lib/AppRoutes';

import Login from './screens/Login';
import Register from './screens/Register';
import ForgotPassword from './screens/ForgotPassword';
import Home from './screens/Home';
import Search from './screens/Search';
import Detail from './screens/Detail';

import { ApiPost, getData, removeData, setData, setError } from './lib/AppHelper';

const LoginRoute = () => {
  const token = useSelector(state => state.token)
  if (token) return <Redirect to={{ pathname: "/home" }} />
  return <Login />
}

const RegisterRoute = () => {
  const token = useSelector(state => state.token)
  if (token) return <Redirect to={{ pathname: "/home" }} />
  return <Register />
}

const ForgotPasswordRoute = () => {
  const token = useSelector(state => state.token)
  if (token) return <Redirect to={{ pathname: "/home" }} />
  return <ForgotPassword />
}

function App() {

  const [loaded, setLoaded] = React.useState(false)
  const queryParams = new URLSearchParams(window.location.search);
  const query_access_token = queryParams.get('access_token');

  React.useEffect(() => {

    async function bootstrap() {
      let token;

      if (query_access_token) {
        await setData('access_token', query_access_token);
        token = query_access_token;
      } else {
        token = await getData('access_token');
      }

      if (token) {
        const response = await ApiPost(`customer/me`);
        if (response.status === 'success') {
          await setData('@email', response.data.email);
          AppStore.dispatch({ type: 'SIGN_IN', user: response.data, token: token })
        } else {
          await removeData('access_token')
          await setError(response.message)
        }
        setLoaded(true);
      } else {
        setLoaded(true);
      }
    }

    bootstrap();

    return () => {
    }
  }, [query_access_token])

  if (!loaded) return false

  return (
    <Provider store={AppStore}>
      <Router>
        <Switch>
          <Route path="/" exact={true}>
            <LoginRoute />
          </Route>
          <Route path="/register">
            <RegisterRoute />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordRoute />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/search/:term?">
            <Search />
          </Route>
          <Route path="/foods/:id">
            <Detail />
          </Route>

          <PrivateRoute>
            <AppRoutes />
          </PrivateRoute>

          <Route path="*" render={() => {
            return <Redirect to="/" />
          }} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
