import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppLogout } from '../lib/AppHelper';
// import { AppStore } from '../lib/AppStore'

export default function Logout() {

   const history = useHistory();
   // AppStore.dispatch({ type: 'LOADING', loading: true })

   React.useEffect(() => {

      async function logout() {
         await AppLogout();
         // AppStore.dispatch({ type: 'LOADING', loading: false })
         // history.push('/login');
      }

      logout();
   }, [history])

   return false
}
