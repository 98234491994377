import React from 'react'
import { connect } from 'react-redux'

export function InputAvatar({ label = "", attrs = {}, error = '', onEnter = () => { }, prefix = "", picture = "", onChange = () => { } }) {

   const inputRef = React.createRef();
   const onChangeHandler = () => {
      var file = inputRef.current.files[0];
      onChange(file);
   }


   return (
      <div className="mb-5">
         <input
            type="file"
            className="hidden"
            ref={inputRef}
            onChange={onChangeHandler}
         />
         <div className="lg:hidden">
            <div className="flex items-center">
               <div
                  className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
                  aria-hidden="true"
               >
                  <img className="rounded-full h-full w-full" src={picture} alt="" />
               </div>
               <div onClick={() => inputRef.current.click()} className="ml-5 rounded-md shadow-sm">
                  <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500">
                     <label
                        htmlFor="user-photo"
                        className="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none"
                     >
                        <span>Change</span>
                        <span className="sr-only"> user photo</span>
                     </label>
                  </div>
               </div>
            </div>
         </div>
         <div onClick={() => inputRef.current.click()} className="hidden relative rounded-full overflow-hidden lg:block w-40 mx-auto">
            <img className="relative rounded-full w-40 h-40" src={picture} alt="" />
            <label
               htmlFor="user-photo"
               className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
            >
               <span>Change</span>
               <span className="sr-only"> user photo</span>
            </label>
         </div>
         {error ? <p className="mt-2 text-sm text-red-500">{error.join(' ')}</p> : false}
      </div>
   )
}

const mapStateToProps = (state) => {
   const { loading } = state
   return { loading: loading }
};

export default connect(mapStateToProps)(props => {
   return <InputAvatar {...props} />
})