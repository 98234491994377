import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";

import Logout from './../screens/Logout';
import Profile from './../screens/Profile';
import Saved from './../screens/Saved';
import { connect } from 'react-redux';

export class AppRoutes extends Component {
   render() {

      return (
         <Switch>
            <Route path="/logout">
               <Logout />
            </Route>

            <Route path="/profile">
               <Profile />
            </Route>
            
            <Route path="/favourites">
               <Saved />
            </Route>

            <Route path="*" render={() => {
               return <Redirect to="/home" />
            }} />
         </Switch>
      )
   }
}

const mapStateToProps = (state) => {
   const { loading, user } = state
   return { loading: loading, user: user }
};

export default connect(mapStateToProps)(props => {
   return <AppRoutes {...props} />
})